var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('MutateCalendarMolecule', {
    attrs: {
      "title": _vm.$t('titles.addCalendar'),
      "availableOwners": _vm.availableOwners
    },
    on: {
      "cancel": function cancel($event) {
        return _vm.$emit('close');
      },
      "confirm": _vm.createCalendar,
      "find-owners": _vm.findOwners
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }