<template>
  <CreateCalendarOverlayStyled>
    <CreateCalendarOrganism @close="$emit('close')" @created="handleCreated" />
  </CreateCalendarOverlayStyled>
</template>
<script>
import { styled } from '@egoist/vue-emotion'
import CreateCalendarOrganism from '@/pages/PlanningApp/Organisms/CreateCalendarOrganism.vue'

const CreateCalendarOverlayStyled = styled('div')`
  width: 100%;
  height: calc(100% - 50px - 1rem);
  max-width: 600px;
  padding: 1rem;
  padding-top: 50px;
  .modal {
    height: 100%;
  }
`

export default {
  components: {
    CreateCalendarOverlayStyled,
    CreateCalendarOrganism,
  },
  props: {
    args: {
      type: Object,
    },
  },
  methods: {
    handleCreated(payload) {
      const calendarId = payload?.data?.createCalendar?.id
      this.args?.createdCallback?.({ calendarId })
    },
  },
}
</script>
