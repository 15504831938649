<template>
  <Modal class="modal" :errors="errors">
    <template v-slot:header>
      {{ title }}
    </template>
    <template v-slot:main>
      <FormStyled v-if="!afterCalendarCreate">
        <FormRowStyled>
          <ValidatableFieldMolecule class="name" :validation="$v.name">
            <EditableFieldMolecule :label="$tc('name', 1)" :value.sync="$v.name.$model" :focus="true" />
          </ValidatableFieldMolecule>
        </FormRowStyled>
        <FormRowStyled>
          <CustomMultiSelect
            class="multi-selector"
            track-by="id"
            openDirection="bottom"
            :value="selectedOwnerType"
            :options="availableOwnerTypes"
            :shouldNotResetSelectedOption="true"
            :closeOnSelect="true"
            :searchable="false"
            :multiple="false"
            :placeholder="$tc('planning.ownerTypePlaceholder')"
            :maxHeight="256"
            :optionsLimit="6"
            :applyFilter="false"
            :internalSearch="true"
            :customNoOptionsSlot="true"
            @select="selectOwnerType"
          >
            <template v-slot:customLabelIcon>
              <ShowAsLabelAtom :label="`${$tc('planning.ownerType')}:`" />
            </template>
            <template v-slot:noOptions>
              <strong>{{ $t('messages.noData') }}</strong>
            </template>
          </CustomMultiSelect>
        </FormRowStyled>
        <FormRowStyled v-if="selectedOwnerType && selectedOwnerType?.id === 'Subsidiary'">
          <CustomMultiSelect
            class="multi-selector"
            track-by="id"
            openDirection="bottom"
            :options="availableOwnersMapped"
            :value="selectedOwner"
            :shouldNotResetSelectedOption="true"
            :disabled="!selectedOwnerType"
            :closeOnSelect="true"
            :searchable="true"
            :placeholder="$tc('planning.ownerPlaceholder')"
            @select="selectOwner"
            @search-change="$emit('find-owners', $event)"
          >
            <template v-slot:customLabelIcon>
              <ShowAsLabel>{{ $tc('owner', 1) }}:</ShowAsLabel>
            </template>
          </CustomMultiSelect>
        </FormRowStyled>
      </FormStyled>
      <FormStyled v-else>
        <div class="message">
          {{ $t('planning.messages.newCalendarCreated', { x: newCalendar?.name }) }}
          <P></P>
          {{ $tc('planning.messages.addNewSchedule') }}
          <ButtonSolidStyled @click="addSchedule"> {{ $tc('planning.addSchedule') }} </ButtonSolidStyled>
        </div>
      </FormStyled>
    </template>
    <template v-slot:footer>
      <ActionsStyled>
        <ButtonBorderedStyled @click="$emit('cancel')">
          {{ $t('actions.cancel') }}
        </ButtonBorderedStyled>
        <ButtonSolidStyled
          @click="
            $emit('confirm', {
              name,
              selectedOwnerId,
              selectedOwnerType: selectedOwnerType.id,
            })
          "
          :disabled="!canConfirm"
        >
          {{ $t('actions.ok') }}
        </ButtonSolidStyled>
      </ActionsStyled>
    </template>
  </Modal>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { validationMixin } from 'vuelidate'
import { required, minLength, requiredIf } from 'vuelidate/lib/validators'
import { Modal, CustomMultiSelect, ShowAsLabelAtom } from '@common/components'

import { flexCenter, flexColumns } from '@styles/mixins'
import { ButtonBordered as ButtonBorderedStyled, ButtonSolid as ButtonSolidStyled } from '@styles/buttons'
import { getUserIdFromToken } from '@common/utils'

import EditableFieldMolecule from '@/components/Atomic/Molecules/EditableFieldMolecule'
import ValidatableFieldMolecule from '@/components/Atomic/Molecules/ValidatableFieldMolecule'

const FormStyled = styled('div')`
  width: 100%;
  max-width: 400px;
  ${flexColumns}
  >.loader {
    margin: 5rem 0;
  }
  .message {
    margin-top: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > button {
      width: 90%;
      margin-top: 3rem;
    }
  }
`

const FormRowStyled = styled('div')`
  ${flexCenter}
  margin-top: 2rem;
  .basic-select {
    width: 100%;
  }
  .name {
    width: 100%;
  }
  .multi-selector {
    width: 100%;
    background-color: ${({ theme }) => (theme.isDark ? theme.colors.panelBG : theme.colors.whiteSmokeDark)};
    border-radius: 0.5rem;
  }

  .error {
    font-size: 12px;
    color: red;
  }
`

const ShowAsLabel = styled('span')`
  color: ${({ theme }) => theme.colors.atomic.textMain};
`

const ActionsStyled = styled('div')`
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
`

export default {
  mixins: [validationMixin],
  props: {
    title: {
      type: String,
      required: true,
    },
    availableOwners: {
      type: Array,
      required: true,
    },
    calendar: {
      type: Object,
    },
  },
  components: {
    Modal,
    FormStyled,
    FormRowStyled,
    ButtonBorderedStyled,
    ButtonSolidStyled,
    ActionsStyled,
    CustomMultiSelect,
    ShowAsLabel,
    ShowAsLabelAtom,
    EditableFieldMolecule,
    ValidatableFieldMolecule,
  },
  data() {
    return {
      name: '',
      selectedOwnerTypeId: null,
      selectedOwnerId: null,
      errors: [],
      isCreating: false,
      afterCalendarCreate: false,
      newCalendar: null,
    }
  },
  validations: {
    name: {
      required,
      minLength: minLength(3),
    },
    selectedOwnerTypeId: {
      required,
    },
    selectedOwnerId: {
      required: requiredIf(selectedOwnerTypeId => selectedOwnerTypeId !== 'User'),
    },
  },
  watch: {
    calendar: {
      handler(calendar) {
        if (!calendar) {
          return
        }
        this.name = calendar.name
        this.selectedOwnerTypeId = calendar.ownerType
        this.selectedOwnerId = calendar.ownerId
      },
      immediate: true,
    },
  },
  computed: {
    selectedOwnerType() {
      return this.availableOwnerTypes.find(f => f.id === this.selectedOwnerTypeId)
    },
    availableOwnerTypes() {
      return [
        {
          id: 'Subsidiary',
          label: this.$tc('subsidiary', 1),
        },
        {
          id: 'User',
          label: this.$tc('planning.self'),
        },
      ]
    },
    availableOwnersMapped() {
      if (this.selectedOwnerType.id === 'Subsidiary') {
        const subs = this.availableOwners.reduce((acc, owner) => acc.concat(owner.subsidiaries), [])
        return subs.map(sub => {
          return {
            id: sub.id,
            label: `${sub?.organization?.alias} - ${sub.name}`,
          }
        })
      }
      return []
    },
    selectedOwner() {
      return this.availableOwnersMapped.find(f => f.id === this.selectedOwnerId)
    },
    canConfirm() {
      return !this.$v.$invalid
    },
  },
  methods: {
    selectOwnerType(type) {
      this.selectedOwnerTypeId = type.id
      if (type.id === 'User') {
        this.selectedOwnerId = getUserIdFromToken(this.$keycloak.token)
      }
      this.$emit('find-owners', '')
    },
    selectOwner({ id }) {
      this.selectedOwnerId = id
    },

    // addSchedule() {
    //   this.args.onConfirm(this.newCalendar)
    // },
  },
}
</script>
