var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Modal', {
    staticClass: "modal",
    attrs: {
      "errors": _vm.errors
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.title) + " ")];
      },
      proxy: true
    }, {
      key: "main",
      fn: function fn() {
        var _vm$selectedOwnerType, _vm$newCalendar;
        return [!_vm.afterCalendarCreate ? _c('FormStyled', [_c('FormRowStyled', [_c('ValidatableFieldMolecule', {
          staticClass: "name",
          attrs: {
            "validation": _vm.$v.name
          }
        }, [_c('EditableFieldMolecule', {
          attrs: {
            "label": _vm.$tc('name', 1),
            "value": _vm.$v.name.$model,
            "focus": true
          },
          on: {
            "update:value": function updateValue($event) {
              return _vm.$set(_vm.$v.name, "$model", $event);
            }
          }
        })], 1)], 1), _c('FormRowStyled', [_c('CustomMultiSelect', {
          staticClass: "multi-selector",
          attrs: {
            "track-by": "id",
            "openDirection": "bottom",
            "value": _vm.selectedOwnerType,
            "options": _vm.availableOwnerTypes,
            "shouldNotResetSelectedOption": true,
            "closeOnSelect": true,
            "searchable": false,
            "multiple": false,
            "placeholder": _vm.$tc('planning.ownerTypePlaceholder'),
            "maxHeight": 256,
            "optionsLimit": 6,
            "applyFilter": false,
            "internalSearch": true,
            "customNoOptionsSlot": true
          },
          on: {
            "select": _vm.selectOwnerType
          },
          scopedSlots: _vm._u([{
            key: "customLabelIcon",
            fn: function fn() {
              return [_c('ShowAsLabelAtom', {
                attrs: {
                  "label": "".concat(_vm.$tc('planning.ownerType'), ":")
                }
              })];
            },
            proxy: true
          }, {
            key: "noOptions",
            fn: function fn() {
              return [_c('strong', [_vm._v(_vm._s(_vm.$t('messages.noData')))])];
            },
            proxy: true
          }], null, false, 1167219942)
        })], 1), _vm.selectedOwnerType && ((_vm$selectedOwnerType = _vm.selectedOwnerType) === null || _vm$selectedOwnerType === void 0 ? void 0 : _vm$selectedOwnerType.id) === 'Subsidiary' ? _c('FormRowStyled', [_c('CustomMultiSelect', {
          staticClass: "multi-selector",
          attrs: {
            "track-by": "id",
            "openDirection": "bottom",
            "options": _vm.availableOwnersMapped,
            "value": _vm.selectedOwner,
            "shouldNotResetSelectedOption": true,
            "disabled": !_vm.selectedOwnerType,
            "closeOnSelect": true,
            "searchable": true,
            "placeholder": _vm.$tc('planning.ownerPlaceholder')
          },
          on: {
            "select": _vm.selectOwner,
            "search-change": function searchChange($event) {
              return _vm.$emit('find-owners', $event);
            }
          },
          scopedSlots: _vm._u([{
            key: "customLabelIcon",
            fn: function fn() {
              return [_c('ShowAsLabel', [_vm._v(_vm._s(_vm.$tc('owner', 1)) + ":")])];
            },
            proxy: true
          }], null, false, 3885590097)
        })], 1) : _vm._e()], 1) : _c('FormStyled', [_c('div', {
          staticClass: "message"
        }, [_vm._v(" " + _vm._s(_vm.$t('planning.messages.newCalendarCreated', {
          x: (_vm$newCalendar = _vm.newCalendar) === null || _vm$newCalendar === void 0 ? void 0 : _vm$newCalendar.name
        })) + " "), _c('P'), _vm._v(" " + _vm._s(_vm.$tc('planning.messages.addNewSchedule')) + " "), _c('ButtonSolidStyled', {
          on: {
            "click": _vm.addSchedule
          }
        }, [_vm._v(" " + _vm._s(_vm.$tc('planning.addSchedule')) + " ")])], 1)])];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('ActionsStyled', [_c('ButtonBorderedStyled', {
          on: {
            "click": function click($event) {
              return _vm.$emit('cancel');
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('actions.cancel')) + " ")]), _c('ButtonSolidStyled', {
          attrs: {
            "disabled": !_vm.canConfirm
          },
          on: {
            "click": function click($event) {
              return _vm.$emit('confirm', {
                name: _vm.name,
                selectedOwnerId: _vm.selectedOwnerId,
                selectedOwnerType: _vm.selectedOwnerType.id
              });
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('actions.ok')) + " ")])], 1)];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }