<template>
  <MutateCalendarMolecule
    :title="$t('titles.addCalendar')"
    :availableOwners="availableOwners"
    @cancel="$emit('close')"
    @confirm="createCalendar"
    @find-owners="findOwners"
  />
</template>

<script>
import { nanoid } from 'nanoid'
import { debounce } from 'vue-debounce'
import MutateCalendarMolecule from '../Molecules/MutateCalendarMolecule.vue'

import MY_ORGANISATIONS_SEARCH_QUERY from '#/graphql/organizations/myOrganizations.gql'
import MY_CALENDARS_LIST_QUERY from '#/graphql/calendar/myCalendarsWithMinimalSchedules.gql'
import CREATE_CALENDAR_MUTATION from '#/graphql/calendar/createMyCalendar.gql'

export default {
  components: {
    MutateCalendarMolecule,
  },
  data() {
    return {
      availableOwners: [],
    }
  },
  methods: {
    async debouncedfindOwners(search) {
      this.searchQuery = search
      const results = await this.$apollo.query({
        query: MY_ORGANISATIONS_SEARCH_QUERY,
        variables: {
          search: this.searchQuery,
        },
      })
      const data = results?.data
      if (!data) {
        return
      }
      this.availableOwners = data?.myOrganizations || []
    },
    async createCalendar({ name, selectedOwnerId, selectedOwnerType }) {
      this.isCreating = true
      let ownerType = null
      if (selectedOwnerType === 'Self') {
        ownerType = 'User'
      } else {
        ownerType = selectedOwnerType
      }
      try {
        const res = await this.$apollo.mutate({
          mutation: CREATE_CALENDAR_MUTATION,
          variables: {
            data: {
              ownerType: ownerType,
              ownerId: selectedOwnerId,
              type: 'OTHER',
              name: name,
            },
          },
          update: (store, { data: { createCalendar } }) => {
            const data = store.readQuery({
              query: MY_CALENDARS_LIST_QUERY,
            })
            data.myCalendars.push(createCalendar)
            store.writeQuery({
              query: MY_CALENDARS_LIST_QUERY,
              data,
            })
          },
        })
        this.$emit('created', res)
      } catch (err) {
        this.errors.push({
          id: nanoid(),
          rawError: err,
        })
        this.isCreating = false
      }
    },
  },
  created() {
    this.findOwners = debounce(search => {
      this.debouncedfindOwners(search)
    }, 150)
  },
}
</script>
